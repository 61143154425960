export const OperatingSystem = {
  Android: 1,
  iOS: 2,
  Other: 3
};

export const getOS = () => {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;
  if (/android/i.test(userAgent)) {
    return OperatingSystem.Android;
  } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return OperatingSystem.iOS;
  } else {
    return OperatingSystem.Other;
  }
};

export const isCodeValid = code => /^[0-9]{10}$/.test(code);

export const isCodeStatic = code => /^S[0-9]{9}$/.test(code);

export const createOffersLookupTable = data => {
  const offers = [];
  data?.campaigns?.forEach(campaign => {
    offers.push(...(campaign?.offers || []));
  });
  offers.push(...(data.offers || []));
  return offers;
};
