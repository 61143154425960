import React, { useState } from 'react';
import IconButton from '../atoms/IconButton';
import { Info } from '../../images/icons';
import Colors from '../../definitions/colors';
import logo from '../../images/logo.svg';
import './NavBar.scss';
import HelpOverlay from './HelpOverlay';
import useResources from '../../hooks/useResources';

const NavBar = () => {
  const [showHelp, setShowHelp] = useState(false);
  const resource = useResources();
  return (
    <div className='NavBar'>
      <img className='NavBar__Logo' src={logo} alt={resource('menu.logoAltText')} />
      <IconButton
        title={resource('menu.help')}
        showLabel
        labelPosition='LEFT'
        onClick={() => setShowHelp(true)}
        iconCircle={{ icon: <Info fill={Colors.White} size={6} />, size: 28 }}
      />
      <HelpOverlay visible={showHelp} onClose={() => setShowHelp(false)} />
    </div>
  );
};

export default NavBar;
