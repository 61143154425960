import React, { useEffect } from 'react';
import { getOS, OperatingSystem } from '../util/helpers';

const withUpdater = BaseComponent => {
  const WithUpdaterComponent = props => {
    // Check version
    const getAppVersion = () => {
      return fetch(`/version.json?c=${Date.now()}`, {
        headers: {
          'Cache-Control': 'no-cache, no-store, must-revalidate'
        }
      }).then(res => res.json());
    };
    const handleFocus = () => {
      const currentVersion = process.env.REACT_APP_VERSION;
      getAppVersion().then(data => {
        if (data.version !== currentVersion) {
          window.location.reload(true);
        }
      });
    };

    useEffect(() => {
      if (getOS() === OperatingSystem.iOS) {
        global.addEventListener('focus', handleFocus, false);
        return () => global.removeEventListener('focus', handleFocus, false);
      }
    }, []);

    return <BaseComponent {...props} />;
  };
  WithUpdaterComponent.displayName = 'withUpdater';
  return WithUpdaterComponent;
};

export default withUpdater;
