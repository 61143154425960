import React from 'react';
import PropTypes from 'prop-types';
import './Textbox.scss';

const Textbox = ({
  classNames,
  disabled,
  inputRef,
  instantValidator,
  name,
  onUpdateValue,
  placeholder,
  required,
  title,
  type,
  value
}) => {
  const validation = instantValidator ? instantValidator(value, !required) : true;
  return (
    <div className={`Textbox ${disabled ? 'Textbox--Disabled' : ''}`}>
      <input
        ref={inputRef}
        className={`Textbox__Input ${classNames}`}
        onChange={event => onUpdateValue(event.currentTarget.value)}
        {...{ disabled, name, placeholder, title, type, value }}
      />
      <div
        className={`Textbox__Validation ${
          validation !== true ? 'Textbox__Validation--Visible' : ''
        }`}
      >
        {validation}
      </div>
    </div>
  );
};

Textbox.propTypes = {
  classNames: PropTypes.string,
  disabled: PropTypes.bool,
  inputRef: PropTypes.object,
  instantValidator: PropTypes.func,
  name: PropTypes.string.isRequired,
  onUpdateValue: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  title: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['text', 'email', 'tel', 'url']).isRequired,
  value: PropTypes.string.isRequired
};

Textbox.defaultProps = {
  type: 'text',
  value: ''
};

export default Textbox;
