import React from 'react';
import PropTypes from 'prop-types';
import './HelpStep.scss';

const HelpStep = ({ iconUrl, altText, children }) => {
  return (
    <div className='HelpStep'>
      <div className='HelpStep__IconWrapper'>
        <img src={iconUrl} alt={altText} className='HelpStep__Icon' />
      </div>
      <div className='HelpStep__Content'>{children}</div>
    </div>
  );
};

HelpStep.propTypes = {
  iconUrl: PropTypes.string.isRequired,
  altText: PropTypes.string.isRequired,
  children: PropTypes.any
};

export default HelpStep;
