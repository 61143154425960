import React, { useEffect, useState } from 'react';
import Overlay from './Overlay';
import PropTypes from 'prop-types';
import { useCodeLookup, useCodeRedemption } from '../../util/hooks';
import CircleSpinner from '../atoms/CircleSpinner';
import './RedeemOverlay.scss';
import CircleIcon from '../atoms/CircleIcon';
import { Alert } from '../../images/icons';
import Colors from '../../definitions/colors';
import Button from '../atoms/Button';
import RedeemItem from '../atoms/RedeemItem';
import Message from '../atoms/Message';
import { dateSpan } from '../../util/formatters';
import { Helmet } from 'react-helmet';
import TextRenderer from './TextRenderer';
import useResources from '../../hooks/useResources';

const RedeemOverlay = ({ onClose, code }) => {
  const [lookupBusy, lookupData, lookupError] = useCodeLookup(code);
  const [codeToRedeem, setCodeToRedeem] = useState(null);
  const [redeemBusy, redeemSuccess, redeemError] = useCodeRedemption(codeToRedeem);
  const resource = useResources();

  const [closing, setClosing] = useState(false);
  useEffect(() => setClosing(false), [code]);

  useEffect(() => {
    if (closing) {
      const to = setTimeout(onClose, 500);
      return () => clearTimeout(to);
    }
  }, [closing]);

  useEffect(() => {
    if (redeemSuccess) {
      const to = setTimeout(onClose, 3500);
      return () => clearTimeout(to);
    }
  }, [redeemSuccess]);
  const failureReason = failureInfo => {
    if (failureInfo.members.includes('Id')) {
      return resource('redeem.invalid.alreadyUsed');
    }
    if (failureInfo.members.includes('ValidFrom')) {
      return resource('redeem.invalid.validFrom');
    }
    if (failureInfo.members.includes('ValidUntil')) {
      return resource('redeem.invalid.validUntil');
    }
    if (failureInfo.members.includes('Limit')) {
      return resource('redeem.invalid.limit');
    }
    return resource('redeem.invalid.other');
  };

  // Reset redemption
  useEffect(() => setCodeToRedeem(null), [code]);

  // Show error if redemption failed
  useEffect(() => {
    if (!redeemBusy && !redeemSuccess && redeemError) {
      alert(resource('redeem.error.general'));
      const to = setTimeout(() => setClosing(true), 3500);
      return () => clearTimeout(to);
    }
  }, [redeemBusy, redeemSuccess, redeemError]);

  return (
    <Overlay
      visible={!!code && !closing}
      onClose={!lookupBusy ? () => setClosing(true) : undefined}
      title={!lookupBusy ? lookupData?.offer?.partner : null}
    >
      {code && (
        <Helmet>
          <body className='has-overlay' />
        </Helmet>
      )}
      <div
        className={`RedeemOverlay__Loading
        ${
          lookupBusy || redeemBusy || (!redeemBusy && redeemSuccess)
            ? 'RedeemOverlay__Loading--Visible'
            : ''
        }
        `}
      >
        <CircleSpinner size={100} complete={redeemSuccess} />
      </div>
      {lookupError && (
        <div className='RedeemOverlay__Exception'>
          <CircleIcon size={100} icon={<Alert size={59} fill={Colors.White} />} offsetY={-5} />
          <p
            className='RedeemOverlay__ExceptionText'
            dangerouslySetInnerHTML={{ __html: resource('redeem.error.notFound') }}
          />
          <Button onClick={() => setClosing(true)} text={resource('redeem.error.close')} />
        </div>
      )}
      {lookupData && (
        <div className='RedeemOverlay__Details'>
          <RedeemItem title={resource('redeem.offer.title')}>
            <p className='RedeemOverlay__Text'>{lookupData?.offer?.title}</p>
          </RedeemItem>
          <RedeemItem title={resource('redeem.offer.validity')}>
            <p className='RedeemOverlay__Text'>
              {dateSpan(lookupData?.offer?.validFrom, lookupData?.offer?.validUntil, resource)}
            </p>
          </RedeemItem>
          {!lookupData?.offer?.definition?.qrCode?.condition ? null : (
            <RedeemItem title={resource('redeem.offer.conditions')}>
              <div className='RedeemOverlay__Text'>
                <TextRenderer content={lookupData.offer.definition.qrCode.condition} />
              </div>
            </RedeemItem>
          )}
          <div className='RedeemOverlay__Message'>
            <Message
              type={!lookupData?.failure ? 'SUCCESS' : 'ALERT'}
              message={
                !lookupData?.failure ? resource('redeem.valid') : failureReason(lookupData?.failure)
              }
            />
          </div>
          {!lookupData?.failure ? (
            <Button onClick={() => setCodeToRedeem(code)} text={resource('redeem.submit')} />
          ) : (
            <Button onClick={() => setClosing(true)} text={resource('redeem.invalid.close')} />
          )}
        </div>
      )}
    </Overlay>
  );
};

RedeemOverlay.propTypes = {
  onClose: PropTypes.func,
  code: PropTypes.string
};

export default RedeemOverlay;
