import moment from 'moment';
moment.locale('de');

export const dateSpan = (from, until, resource) => {
  const dateFormat = 'DD.MM.YYYY';
  if (from && until) {
    return resource('validity.fromUntil', {
      from: moment(from).format(dateFormat),
      until: moment(until).format(dateFormat)
    });
  }
  if (from && !until) {
    return resource('validity.from', { from: moment(from).format(dateFormat) });
  }
  if (!from && until) {
    return resource('validity.until', { until: moment(until).format(dateFormat) });
  }
  return resource('validity.always');
};

export const sanitizeWebUrl = url => {
  return /^https?:\/\/(.)*/.test(url) ? url : `https://${url}`;
};

export const sanitizePhoneUrl = phone => {
  return `tel:${phone.replace(/[^0-9]/g, '')}`;
};
