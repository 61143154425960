import React from 'react';
import PropTypes from 'prop-types';
import CircleIcon from './CircleIcon';
import { Alert, Tick } from '../../images/icons';
import Colors from '../../definitions/colors';
import './Message.scss';

const Message = ({ type, message }) => {
  return (
    <div className={`Message Message--${type}`}>
      <div className='Message__Icon'>
        {type === 'SUCCESS' ? (
          <CircleIcon
            icon={<Tick size={15} fill={Colors.White} />}
            color={Colors.Green}
            size={28}
          />
        ) : (
          <CircleIcon
            icon={<Alert size={15} fill={Colors.White} />}
            color={Colors.OrangeAlert}
            size={28}
            offsetY={-1}
          />
        )}
      </div>
      <div className='Message__Text'>{message}</div>
    </div>
  );
};

Message.propTypes = {
  type: PropTypes.oneOf(['SUCCESS', 'ALERT']).isRequired,
  message: PropTypes.string.isRequired
};

export default Message;
