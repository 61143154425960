import React, { useEffect } from 'react';
import './App.scss';
import NavBar from './modules/NavBar';
import QrScanner from './modules/QrScanner';
import useResources from '../hooks/useResources';
import { useDispatch, useSelector } from 'react-redux';
import { fetchResources } from '../redux/reducers/resources';
import withStore from '../wrappers/withStore';
import withUpdater from '../wrappers/withUpdater';
import { selectAppReady } from '../redux/rootReducer';

function App() {
  const resource = useResources();
  const dispatch = useDispatch();
  const appReady = useSelector(selectAppReady);

  /* Load resoruces from API */
  useEffect(() => {
    dispatch(fetchResources());
  }, []);

  /* Hide global loading spinner */
  useEffect(() => {
    if (appReady) {
      const overlay = document.getElementsByClassName('ROOT__Spinner')[0];
      overlay.style.opacity = 0;
      overlay.style.visibility = 'hidden';
    }
  }, [appReady]);

  return (
    <div className='App'>
      <NavBar />
      <div className='App__Main'>
        <QrScanner />
      </div>
      <div className='App__Meta'>
        <a
          href={resource('imprint.url')}
          target='_blank'
          rel='noopener noreferrer'
          title={resource('imprint.linkText')}
          className='App__MetaLink'
        >
          {resource('imprint.linkText')}
        </a>
        <a
          href={resource('privacy.url')}
          target='_blank'
          rel='noopener noreferrer'
          title={resource('privacy.linkText')}
          className='App__MetaLink'
        >
          {resource('privacy.linkText')}
        </a>
      </div>
    </div>
  );
}

export default withUpdater(withStore(App));
