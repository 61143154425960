import React from 'react';
import PropTypes from 'prop-types';
import './WysiwygStyling.scss';

const WysiwygStyling = ({ children, html }) => {
  return html ? (
    <div className='WysiwygStyling' dangerouslySetInnerHTML={{ __html: html }} />
  ) : (
    <div className='WysiwygStyling'>{children}</div>
  );
};

WysiwygStyling.propTypes = {
  children: PropTypes.any,
  html: PropTypes.string
};

export default WysiwygStyling;
