import React from 'react';
import PropTypes from 'prop-types';

const IconPropTypes = {
  size: PropTypes.number,
  fill: PropTypes.string
};

export const Info = ({ size, fill }) => (
  <svg version='1.1' width={size} viewBox='0 0 6.7 19.8'>
    <path
      fill={fill}
      d='M3.3,6.7C2,6.7,0.8,5.9,0.3,4.6S0,1.9,1,1s2.4-1.2,3.6-0.7S6.7,2,6.7,3.3C6.7,5.2,5.2,6.7,3.3,6.7
	L3.3,6.7z M3.3,1.3c-0.8,0-1.6,0.5-1.9,1.3S1.3,4.2,1.9,4.8s1.5,0.8,2.3,0.4s1.3-1.1,1.3-1.9C5.4,2.2,4.5,1.3,3.3,1.3z'
    />
    <path
      fill={fill}
      d='M5.9,19.8H0.7c-0.4,0-0.6-0.3-0.6-0.6v-10c0-0.4,0.3-0.6,0.6-0.6h5.2c0.4,0,0.6,0.3,0.6,0.6v5.4
	c0,0.4-0.3,0.6-0.6,0.6s-0.6-0.3-0.6-0.6V9.8H1.4v8.7h4.5c0.4,0,0.6,0.3,0.6,0.6S6.3,19.8,5.9,19.8L5.9,19.8z'
    />
  </svg>
);
Info.propTypes = IconPropTypes;

export const Refresh = ({ size, fill }) => (
  <svg version='1.1' width={size} viewBox='0 0 14.6 13'>
    <path
      fill={fill}
      d='M14.3,4.3c-0.3-0.2-0.6-0.1-0.8,0.2l-0.3,0.4C12.6,2.1,10.1,0,7.2,0C4.9,0,2.9,1.2,1.8,3.1C1.6,3.4,1.7,3.8,2,3.9
	C2.3,4.1,2.7,4,2.8,3.7c0.9-1.6,2.6-2.5,4.3-2.5c2.4,0,4.4,1.7,4.9,4l-0.5-0.3c-0.3-0.2-0.6-0.1-0.8,0.2c-0.2,0.3-0.1,0.6,0.2,0.8
	L12.6,7c0.1,0.1,0.2,0.1,0.3,0.1c0.2,0,0.4-0.1,0.5-0.3l1.1-1.6C14.7,4.9,14.6,4.5,14.3,4.3z'
    />
    <path
      fill={fill}
      d='M12.6,9c-0.3-0.2-0.7-0.1-0.8,0.2c-0.9,1.6-2.6,2.5-4.3,2.5c-2.4,0-4.4-1.7-4.9-4L3,8.1c0.3,0.2,0.6,0.1,0.8-0.2
	C4,7.7,4,7.3,3.7,7.1L2,6C1.9,5.9,1.8,5.9,1.6,5.9C1.4,5.9,1.3,6,1.2,6.2L0.1,7.8C-0.1,8.1,0,8.4,0.3,8.6c0.1,0.1,0.2,0.1,0.3,0.1
	c0.2,0,0.4-0.1,0.5-0.3l0.3-0.4C2,10.9,4.5,13,7.4,13c2.2,0,4.3-1.2,5.4-3.1C13,9.5,12.9,9.2,12.6,9z'
    />
  </svg>
);
Refresh.propTypes = IconPropTypes;

export const Close = ({ size, fill }) => (
  <svg version='1.1' width={size} viewBox='0 0 24 24'>
    <path
      fill={fill}
      d='M13.8,12l9.4-9.4c0.3-0.3,0.5-0.8,0.4-1.3c-0.1-0.4-0.5-0.8-0.9-0.9s-0.9,0-1.3,0.4L12,10.2L2.6,0.8
	C2.3,0.5,1.8,0.3,1.4,0.5S0.6,0.9,0.5,1.4s0,0.9,0.4,1.3l9.4,9.4l-9.4,9.4c-0.4,0.4-0.5,0.9-0.3,1.4c0.2,0.5,0.7,0.8,1.2,0.8
	c0.3,0,0.7-0.1,0.9-0.4l9.4-9.4l9.4,9.4c0.3,0.3,0.8,0.5,1.3,0.4c0.4-0.1,0.8-0.5,0.9-0.9c0.1-0.4,0-0.9-0.4-1.3L13.8,12z'
    />
  </svg>
);
Close.propTypes = IconPropTypes;

export const Download = ({ size, fill }) => (
  <svg version='1.1' width={size} viewBox='0 0 29 31.6'>
    <path
      fill={fill}
      d='M25.7,31.6H3.3c-1.8,0-3.3-1.5-3.3-3.3v-3.6c0-0.7,0.6-1.3,1.3-1.3s1.3,0.6,1.3,1.3v3.6c0,0.2,0.1,0.4,0.2,0.5
	S3.2,29,3.3,29h22.3c0.2,0,0.4-0.1,0.5-0.2c0.1-0.1,0.2-0.3,0.2-0.5v-3.6c0-0.7,0.6-1.3,1.3-1.3s1.3,0.6,1.3,1.3v3.6
	C29,30.1,27.5,31.6,25.7,31.6z'
    />
    <path
      fill={fill}
      d='M14.5,24.2L14.5,24.2c-0.3,0-0.7-0.1-0.9-0.4L7.8,18c-0.5-0.5-0.5-1.3,0-1.9c0.5-0.5,1.3-0.5,1.9,0l4.8,4.8
	l4.8-4.8c0.5-0.5,1.3-0.5,1.8,0c0.5,0.5,0.5,1.3,0,1.8l-5.7,5.7C15.2,24,14.9,24.2,14.5,24.2z'
    />
    <path
      fill={fill}
      d='M14.5,16.2L14.5,16.2c-0.7,0-1.3-0.6-1.3-1.3V1.3c0-0.7,0.6-1.3,1.3-1.3l0,0c0.7,0,1.3,0.6,1.3,1.3v13.5
	C15.8,15.6,15.2,16.2,14.5,16.2L14.5,16.2z'
    />
  </svg>
);
Download.propTypes = IconPropTypes;

export const Alert = ({ size, fill }) => (
  <svg version='.1' width={size} viewBox='0 0 59.1 54.4'>
    <path
      fill={fill}
      d='M56.8,54.4c-0.8,0-1.6-0.5-2-1.2L29.5,6.9l-23.4,43h32.6c1.3,0,2.3,1,2.3,2.3c0,1.3-1,2.3-2.3,2.3H2.3
	c-0.8,0-1.5-0.4-2-1.1s-0.4-1.5,0-2.3l27.3-50C28,0.4,28.7,0,29.5,0s1.6,0.4,2,1.1l27.3,50c0.6,1.1,0.2,2.5-0.9,3.1
	C57.6,54.3,57.2,54.4,56.8,54.4z'
    />
    <path
      fill={fill}
      d='M29.5,46.8c-1.3,0-2.3-1-2.3-2.3V31.7c0-1.3,1-2.3,2.3-2.3c1.3,0,2.3,1,2.3,2.3v12.8c0,0.6-0.2,1.2-0.7,1.6
	C30.7,46.5,30.2,46.8,29.5,46.8z'
    />
    <path
      fill={fill}
      d='M29.5,24.9c-0.6,0-1.2-0.2-1.6-0.6c-0.4-0.4-0.7-1-0.7-1.6c0-0.6,0.2-1.2,0.6-1.6c0.9-0.9,2.3-0.9,3.2,0
	c0.4,0.4,0.7,1,0.7,1.6C31.8,23.9,30.8,24.9,29.5,24.9L29.5,24.9z'
    />
  </svg>
);
Alert.propTypes = IconPropTypes;

export const Tick = ({ size, fill }) => (
  <svg version='1.1' width={size} viewBox='0 0 16 12'>
    <path
      fill={fill}
      d='M5.3,11.3c-0.2,0-0.3-0.1-0.4-0.2L0.2,6.5C0,6.3,0,6.1,0,5.8c0.1-0.2,0.2-0.4,0.5-0.5s0.5,0,0.6,0.2l4.2,4.2
	l8.9-8.9c0.2-0.2,0.4-0.2,0.6-0.2c0.2,0.1,0.4,0.2,0.5,0.5s0,0.5-0.2,0.6l-9.3,9.3C5.6,11.2,5.5,11.3,5.3,11.3z'
    />
  </svg>
);
Tick.propTypes = IconPropTypes;
