import { useSelector } from 'react-redux';
import { selectResources } from '../redux/reducers/resources';
import defaultResources from '../definitions/defaultResources.json';

/* Returns a helper function that yields resource texts based on the key */
export default function useResources(namespace = 'partner') {
  const resources = useSelector(selectResources);

  return (key, replacements = {}) => {
    // Find resource value
    let value = `${namespace}.${key}`;
    if (Object.prototype.hasOwnProperty.call(resources, `${namespace}.${key}`)) {
      // Search resources from API
      value = resources[`${namespace}.${key}`];
    } else if (Object.prototype.hasOwnProperty.call(defaultResources, `${namespace}.${key}`)) {
      // Search default resources from local file
      value = defaultResources[`${namespace}.${key}`];
    }

    // Run replacements
    for (const [placeholder, replaceWith] of Object.entries(replacements)) {
      value = value.replaceAll(`{${placeholder}}`, replaceWith);
    }

    return value;
  };
}
