/**
 * Map static (fake) alias codes to offer IDs or complete offers to simulate redemption within the partner app
 */

const StaticCodes = {
  S957245086: 'e02b6369-ba1f-4584-6bab-08d92cc75cf6',
  S721849486: {
    definition: {
      article: null,
      qrCode: {
        condition: {
          value:
            '<p>Gültig nur auf eigene Produkte von Pfeffersack &amp; Soehne, nicht auf Fremdprodukte.&nbsp;</p>',
          type: 'text/html',
          richMedia: []
        },
        limit: null,
        buttonWebsiteUsedName: null,
        buttonRedeemUsedName: null
      },
      shopify: null,
      isArticle: false,
      isQrCode: true,
      isShopify: false
    },
    validFrom: '2021-09-01T00:00:00+02:00',
    validUntil: '2021-10-31T23:59:59+02:00',
    title: 'Gewürze entdecken',
    excerpt:
      'Rabatt auf alle Gewürze und Keramikartikel von Pfeffersack & Soehne in den Läden in der Koblenzer Altstadt oder im Laden in der Werkstadt Limburg.',
    description: '15 % Rabatt',
    descriptionLong: {
      value:
        '<p>Stangenpfeffer aus Indonesien, Tonkabohnen aus Brasilien, Kerbel aus Deutschland. Oder doch lieber Vanille aus Madagaskar? Für Pfeffersack &amp; Soehne kommen nur die besten und edelsten Gewürze der Welt in Frage &ndash; und in die Dose. Zusätzlich arbeitet die Manufaktur ständig an neuen Gewürzmischungen, die jeden Gaumen auf eine kleine Weltreise schicken. Mit dem Entdecker-Bonus erhalten evm-Kunden 15 % Rabatt auf alle Gewürze und Keramikartikel von Pfeffersack &amp; Soehne in den Läden in der Koblenzer Altstadt oder im Laden in der Werkstadt Limburg.&nbsp;</p>',
      type: 'text/html',
      richMedia: []
    },
    images: [
      {
        location:
          'https://entdeckerbonusfiles.blob.core.windows.net/media/b865cf4d-1fbe-4228-8497-0bf444213e27',
        type: 'image/jpeg',
        alias: null
      },
      {
        location:
          'https://entdeckerbonusfiles.blob.core.windows.net/media/5cd5442b-450a-44c3-8a14-e05b9dd51f0f',
        type: 'image/jpeg',
        alias: null
      },
      {
        location:
          'https://entdeckerbonusfiles.blob.core.windows.net/media/f87da340-a45a-4e22-97f2-5b8c993a198b',
        type: 'image/jpeg',
        alias: null
      }
    ],
    partner: 'Pfeffersack & Soehne',
    partnerLogo: {
      location:
        'https://entdeckerbonusfiles.blob.core.windows.net/media/534409f6-9ee9-4224-92ea-994adfeb8837',
      type: 'image/png',
      alias: null
    },
    partnerDescription: {
      value:
        '<p>Pfeffersack &amp; Soehne liefert die Gew&uuml;rze der Welt in die K&uuml;che und steht dabei f&uuml;r kompromisslose Qualit&auml;t. Und das auch noch in mehrfach ausgezeichnet sch&ouml;ner Verpackung &ndash; in Handarbeit gefertigten Keramikdosen aus dem nahen Westerwald.</p>',
      type: 'text/html',
      richMedia: []
    },
    address:
      'Kontor Koblenz\nAn der Liebfrauenkirche 1\n56068 Koblenz\n \nKontor Limburg \nBahnhofsplatz 2 (WERKStadt) \nLimburg',
    addressObject: null,
    infoWebsite: 'www.pfeffersackundsoehne.de',
    infoPhone: null
  },
  S843011321: 'a90e8d93-1435-456a-81df-08d999407055',
  S914385311: {
    partner: 'Konditorei Café Baumann',
    title: 'Dürfen es Pralinen oder Trüffel sein?',
    validFrom: '2022-03-01T00:00:00+01:00',
    validUntil: '2022-06-30T23:59:59+02:00',
    definition: {
      qrCode: {
        condition: {
          value:
            '<p>Der Vorteil gilt ab einem Einkaufswert von mindestens 150 g Pralinen oder Trüffel.</p>',
          type: 'text/html',
          richMedia: []
        }
      }
    }
  }
};

export default StaticCodes;
