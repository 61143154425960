import { useEffect, useState } from 'react';
import { apiCall } from './api';
import { isCodeValid, createOffersLookupTable } from './helpers';
import StaticCodes from '../definitions/staticCodes';

const CodeMode = {
  Real: 1,
  Static: 2
};

export const useCodeLookup = code => {
  const [busy, setBusy] = useState(false);
  const [data, setData] = useState(null);
  const [hasError, setHasError] = useState(false);
  const mode = isCodeValid(code) ? CodeMode.Real : CodeMode.Static;

  // Make API request
  const lookupCode = code => {
    if (code) {
      setBusy(true);
      setHasError(false);
      setData(null);

      if (mode === CodeMode.Real) {
        // Lookup real alias
        apiCall(`/redeem/verify/qr/${code}`)
          .then(response => {
            if (response.status !== 200) {
              throw new Error('Alias could not be verified');
            }
            return response.json();
          })
          .then(data => {
            setData(data);
            setHasError(false);
          })
          .catch(() => {
            setData(null);
            setHasError(true);
          })
          .finally(() => {
            setBusy(false);
          });
      } else {
        // Lookup offer for static alias
        const offerLookup = StaticCodes[code] || null;
        if (offerLookup) {
          if (typeof offerLookup === 'string' || offerLookup instanceof String) {
            // Actual offer code that requires look-up from API
            apiCall(`/public`)
              .then(response => {
                if (response.status !== 200) {
                  throw new Error('Offers could not be loaded');
                }
                return response.json();
              })
              .then(offerData => {
                const offers = createOffersLookupTable(offerData);
                const offer = offers.find(offer => offer.id === offerLookup);
                if (offer) {
                  setData({
                    offer,
                    failure: null
                  });
                  setHasError(false);
                } else {
                  // Offer not found
                  throw new Error('Offer not found');
                }
              })
              .catch(() => {
                setData(null);
                setHasError(true);
              })
              .finally(() => {
                setBusy(false);
              });
          } else {
            // Static lookup that serves the data directly
            setData({
              offer: offerLookup,
              failure: null
            });
            setHasError(false);
            setBusy(false);
          }
        } else {
          // Offer not found
          setBusy(false);
          setData(null);
          setHasError(true);
        }
      }
    } else {
      // No code is set
      setBusy(false);
      setData(null);
      setHasError(false);
    }
  };
  useEffect(() => lookupCode(code), [code]);

  return [busy, data, hasError];
};

export const useCodeRedemption = code => {
  const [busy, setBusy] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const mode = isCodeValid(code) ? CodeMode.Real : CodeMode.Static;

  // Make API request
  const redeemCode = code => {
    if (code) {
      setBusy(true);
      setSuccess(false);
      setError(false);

      if (mode === CodeMode.Real) {
        // Make true redemption to API
        apiCall(`/redeem/activate/qr/${code}`, 'POST')
          .then(response => {
            if (response.status !== 202) {
              throw new Error('Alias could not be activated.');
            }
            return true; // Response does not contain a body
          })
          .then(() => {
            setSuccess(true);
            setError(false);
          })
          .catch(() => {
            setSuccess(false);
            setError(true);
          })
          .finally(() => {
            setBusy(false);
          });
      } else {
        // Fake redemption with timeout
        const fakeRedemption = new Promise(res => setTimeout(res, 500));
        fakeRedemption.then(() => {
          setSuccess(true);
          setError(false);
          setBusy(false);
        });
      }
    } else {
      // No code is set
      setBusy(false);
      setSuccess(false);
      setError(false);
    }
  };
  useEffect(() => redeemCode(code), [code]);

  return [busy, success, error];
};
