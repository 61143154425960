import React, { useState } from 'react';
import Overlay from './Overlay';
import PropTypes from 'prop-types';
import HelpStep from '../atoms/HelpStep';
import './HelpOverlay.scss';

import IconScan from '../../images/help_scan.svg';
import IconCheck from '../../images/help_check.svg';
import IconRedeem from '../../images/help_redeem.svg';
import IconDone from '../../images/help_done.svg';
import Button from '../atoms/Button';
import IconButton from '../atoms/IconButton';
import { Download } from '../../images/icons';
import Colors from '../../definitions/colors';
import InstallOverlay from './InstallOverlay';
import { Helmet } from 'react-helmet';
import { getOS, OperatingSystem } from '../../util/helpers';
import useResources from '../../hooks/useResources';
import { sanitizePhoneUrl } from '../../util/formatters';

const HelpOverlay = ({ visible, onClose }) => {
  const [showInstall, setShowInstall] = useState(false);
  const resource = useResources();

  const isInstalled = window.matchMedia('(display-mode: standalone)').matches;
  const os = getOS();
  return (
    <>
      <Overlay title={resource('help.title')} {...{ visible, onClose }}>
        {visible && (
          <Helmet>
            <body className='has-overlay' />
          </Helmet>
        )}
        <div className='HelpOverlay__Steps'>
          <HelpStep altText={resource('help.steps.1.imageAltText')} iconUrl={IconScan}>
            <p
              className='HelpOverlay__StepText'
              dangerouslySetInnerHTML={{ __html: resource('help.steps.1.text') }}
            />
          </HelpStep>
          <HelpStep altText={resource('help.steps.2.imageAltText')} iconUrl={IconCheck}>
            <p
              className='HelpOverlay__StepText'
              dangerouslySetInnerHTML={{ __html: resource('help.steps.2.text') }}
            />
          </HelpStep>
          <HelpStep altText={resource('help.steps.3.imageAltText')} iconUrl={IconRedeem}>
            <p
              className='HelpOverlay__StepText'
              dangerouslySetInnerHTML={{ __html: resource('help.steps.3.text') }}
            />
          </HelpStep>
          <HelpStep altText={resource('help.steps.4.imageAltText')} iconUrl={IconDone}>
            <p
              className='HelpOverlay__StepText'
              dangerouslySetInnerHTML={{ __html: resource('help.steps.4.text') }}
            />
          </HelpStep>
        </div>
        <div className='HelpOverlay__DismissButtonWrapper'>
          <Button text={resource('help.close.text')} onClick={onClose} />
        </div>
        <div className='HelpOverlay__QrLinkWrapper'>
          <a
            href='/evm_Entdecker-Bonus_Anleitung.pdf'
            target='_blank'
            rel='noopener'
            title={resource('help.downloadPdf.text')}
            className='HelpOverlay__QrLink'
          >
            {resource('help.downloadPdf.text')}
          </a>
        </div>
        <div className='HelpOverlay__Contact'>
          <div className='HelpOverlay__ContactTitle'>{resource('help.contact.title')}</div>
          <p
            className='HelpOverlay__ContactText'
            dangerouslySetInnerHTML={{ __html: resource('help.contact.text') }}
          />
          <p className='HelpOverlay__ContactText'>
            {resource('help.contact.phone.label') + ' '}
            <a
              href={sanitizePhoneUrl(resource('help.contact.phone.number'))}
              title={resource('help.contact.phone.number')}
              className='HelpOverlay__QrLink'
            >
              {resource('help.contact.phone.number')}
            </a>
          </p>
        </div>
        {!isInstalled && os !== OperatingSystem.Other && (
          <div className='HelpOverlay__InstallWrapper'>
            <p
              className='HelpOverlay__InstallText'
              dangerouslySetInnerHTML={{ __html: resource('help.install.text') }}
            />
            <IconButton
              title={resource('help.install.button')}
              iconCircle={{ icon: <Download fill={Colors.White} size={28} />, size: 58 }}
              onClick={() => setShowInstall(true)}
              iconOffsetY={-2}
            />
          </div>
        )}
        <div className='HelpOverlay__Version'>
          Version {process.env.REACT_APP_VERSION.substr(0, 6)}
        </div>
      </Overlay>
      <InstallOverlay visible={showInstall} onClose={() => setShowInstall(false)} />
    </>
  );
};

HelpOverlay.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func
};

export default HelpOverlay;
