export const apiCall = (endpoint, method = 'GET', data = null) => {
  return fetch(`${process.env.REACT_APP_API_INTERACT}${endpoint}`, {
    method,
    cache: 'no-cache',
    credentials: 'same-origin',
    redirect: 'follow',
    headers: {
      'Ocp-Apim-Subscription-Key': '657b98dfa5b948dfb40b037a3bd4c7b7',
      'X-Client': 'PartnerApp',
      ...(data && method === 'POST' && { 'Content-Type': 'application/json' })
    },
    ...(data && { body: JSON.stringify(data) })
  }).then(response => {
    // Introduce an artificial delay, if environment variable is set
    if (process.env.REACT_APP_API_DELAY) {
      return new Promise(resolve =>
        setTimeout(() => resolve(response), process.env.REACT_APP_API_DELAY)
      );
    }
    return response;
  });
};
