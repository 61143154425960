import React from 'react';
import PropTypes from 'prop-types';
import './RedeemItem.scss';

const RedeemItem = ({ title, children }) => {
  return (
    <div className='RedeemItem'>
      <div className='RedeemItem__Title'>{title}</div>
      <div className='RedeemItem__Content'>{children}</div>
    </div>
  );
};

RedeemItem.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.any
};

export default RedeemItem;
