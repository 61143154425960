import React from 'react';
import PropTypes from 'prop-types';
import './InstallOverlay.scss';
import useResources from '../../hooks/useResources';

const InstallOverlay = ({ visible, onClose }) => {
  const resource = useResources();
  return (
    <div
      className={`InstallOverlay ${visible ? 'InstallOverlay--In' : 'InstallOverlay--Out'}`}
      onClick={onClose}
    >
      <p
        className={`InstallOverlay__Text ${
          visible ? 'InstallOverlay__Text--In' : 'InstallOverlay__Text--Out'
        }`}
        dangerouslySetInnerHTML={{ __html: resource('install.text') }}
      />
    </div>
  );
};

InstallOverlay.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func
};

export default InstallOverlay;
