import React, { useEffect, useRef, useState } from 'react';
import QrReader from 'modern-react-qr-reader';
import './QrScanner.scss';
import Colors from '../../definitions/colors';
import { Refresh, Tick } from '../../images/icons';
import IconButton from '../atoms/IconButton';
import RedeemOverlay from './RedeemOverlay';
import Textbox from '../atoms/inputs/Textbox';
import useInterval from 'react-useinterval';
import useResources from '../../hooks/useResources';
import { isCodeValid, isCodeStatic } from '../../util/helpers';

const QrScanner = () => {
  const [currentCode, setCurrentCode] = useState(null);
  const [codeManualInput, setCodeManualInput] = useState('');
  const [facingMode, setFacingMode] = useState('environment');
  const [standby, setStandby] = useState(false);
  const [renderQrReader, setRenderQrReader] = useState(true);
  const manualInputForm = useRef();
  const manualInput = useRef();
  const resource = useResources();

  useEffect(() => {
    if (!currentCode) {
      setStandby(false);
      setRenderQrReader(true);
    } else {
      // Hide
      setStandby(true);
      const to = setTimeout(() => setRenderQrReader(false), 300);
      return () => clearTimeout(to);
    }
  }, [currentCode]);

  useInterval(() => {
    if (renderQrReader) {
      // Force re-render
      setRenderQrReader(false);
      const to = setTimeout(() => setRenderQrReader(true), 1);
      return () => clearTimeout(to);
    }
  }, 30000);

  const handleScan = data => {
    if (!data) {
      return;
    }
    const cleanScan = data.replace(/\s+/g, '');
    if (
      cleanScan.trim() !== currentCode &&
      (isCodeValid(cleanScan.trim()) || isCodeStatic(cleanScan.trim()))
    ) {
      setCurrentCode(cleanScan.trim());
    }
  };

  const handleFacingModeChange = () => {
    setFacingMode(facingMode === 'environment' ? 'user' : 'environment');
  };

  const enterManualCode = value => {
    if (/^[S0-9]*$/.test(value)) {
      setCodeManualInput(value);
    }
  };

  const submitManualCode = event => {
    event.preventDefault();
    if (manualInput.current) {
      manualInput.current.blur();
    }
    setCurrentCode(codeManualInput);
  };

  const resetView = () => {
    setCurrentCode(null);
    setCodeManualInput('');
  };

  return (
    <>
      <div className='QrScanner'>
        <h1 className='QrScanner__Title'>{resource('scan.title')}</h1>
        <div className='QrScanner__Scan'>
          <div className='QrScanner__Reader'>
            <div className={`QrScanner__Dummy ${standby ? 'QrScanner__Dummy--Visible' : ''}`} />
            {!renderQrReader ? null : (
              <QrReader
                delay={250}
                onError={() => {}}
                onScan={handleScan}
                showViewFinder={false}
                facingMode={facingMode}
                className='QrScanner__ViewFinder'
              />
            )}
            <svg className='QrScanner__Frame' viewBox='0 0 305 305'>
              <polygon
                fill='#F39100'
                points='282.8,4 300.6,4 300.6,22.4 304.6,22.4 304.6,0 282.8,0 '
              />
              <polygon fill='#F39100' points='4,4 22.7,4 22.7,0 0,0 0,22.4 4,22.4 ' />
              <polygon
                fill='#F39100'
                points='300.6,282.6 300.6,300.6 282.8,300.6 282.8,304.6 304.6,304.6 304.6,282.6 '
              />
              <polygon
                fill='#F39100'
                points='22.7,300.6 4,300.6 4,282.6 0,282.6 0,304.6 22.7,304.6 '
              />
            </svg>
          </div>
          <div className='QrScanner__SwitchCamera'>
            <IconButton
              title={resource('scan.switchCamera')}
              showLabel
              iconCircle={{ icon: <Refresh size={14} fill={Colors.White} />, size: 28 }}
              onClick={handleFacingModeChange}
            />
          </div>
        </div>
        <div className='QrScanner__Manual'>
          <p
            className='QrScanner__Text'
            dangerouslySetInnerHTML={{ __html: resource('scan.manualInput.text') }}
          />
          <form
            action='/'
            ref={manualInputForm}
            className='QrScanner__Input'
            onSubmit={submitManualCode}
          >
            <Textbox
              inputRef={manualInput}
              name='code'
              title={resource('scan.manualInput.textboxLabel')}
              placeholder={'XXXXXXXXXX'}
              value={codeManualInput}
              onUpdateValue={enterManualCode}
              classNames='QrScanner__Textbox'
              instantValidator={input =>
                !input ||
                isCodeValid(input) ||
                isCodeStatic(input) ||
                resource('scan.manualInput.invalidMessage')
              }
            />
            <div className='QrScanner__ManualButton'>
              <IconButton
                title={resource('scan.manualInput.submit')}
                iconCircle={{ icon: <Tick size={17} fill={Colors.White} />, size: 32 }}
                disabled={!isCodeValid(codeManualInput) && !isCodeStatic(codeManualInput)}
                onClick={submitManualCode}
              />
            </div>
          </form>
        </div>
      </div>
      <RedeemOverlay code={currentCode} onClose={resetView} />
    </>
  );
};

export default QrScanner;
