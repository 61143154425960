import React from 'react';
import PropTypes from 'prop-types';
import WysiwygStyling from './WysiwygStyling';
import ReactMarkdown from 'react-markdown';

const TextRenderer = ({ content, plainTextClassName }) => {
  if (!content) return null;
  const { type, value } = content;
  switch (type) {
    case 'text/html':
      return <WysiwygStyling html={value} />;
    case 'text/markdown':
      return (
        <WysiwygStyling>
          <ReactMarkdown>{value}</ReactMarkdown>
        </WysiwygStyling>
      );
    default:
      return plainTextClassName ? <div className={plainTextClassName}>{value}</div> : value;
  }
};

TextRenderer.propTypes = {
  content: PropTypes.shape({
    value: PropTypes.string,
    type: PropTypes.string,
    richMedia: PropTypes.array
  }),
  plainTextClassName: PropTypes.string
};

export default TextRenderer;
