import React from 'react';
import PropTypes from 'prop-types';
import IconButton from '../atoms/IconButton';
import { Close } from '../../images/icons';
import Colors from '../../definitions/colors';
import './Overlay.scss';
import useResources from '../../hooks/useResources';

const Overlay = ({ title, onClose, visible, children }) => {
  const resource = useResources();
  return (
    <div className={`Overlay ${visible ? 'Overlay--In' : 'Overlay--Out'}`} onClick={onClose}>
      <div
        className={`Overlay__Wrapper ${visible ? 'Overlay__Wrapper--In' : 'Overlay__Wrapper--Out'}`}
        onClick={event => event.stopPropagation()}
      >
        {onClose && (
          <div className='Overlay__Close'>
            <IconButton
              title={resource('overlay.close')}
              iconCircle={{ icon: <Close size={12} fill={Colors.White} />, size: 28 }}
              onClick={onClose}
            />
          </div>
        )}
        {title && <div className='Overlay__Title'>{title}</div>}
        <div className='Overlay__Content'>{children}</div>
      </div>
    </div>
  );
};

Overlay.propTypes = {
  visible: PropTypes.bool,
  title: PropTypes.string,
  onClose: PropTypes.func,
  children: PropTypes.any
};

export default Overlay;
